<template>
  <div id="clock">
    <span
      :class="{ redColor: under5, time: !under5 }"
      id="time"
      v-html="time"
    ></span>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      time: "00.000",
      interval: null,
      running: false,
      under5: false,
      started: null,
      timeBegan: null,
      countDownTime: 20000,
    };
  },
  mounted: function () {},
  destroyed: function () {
    this.reset();
  },
  methods: {
    reset: function () {
      this.running = false;
      clearInterval(this.started);
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = "00.000";
      this.under5 = false;
    },

    start: function () {
      console.log("come here?");
      this.reset();
      if (this.timeBegan == null) {
        this.timeBegan = new Date();
      }
      this.started = setInterval(this.clockRunning, 50);
      this.running = true;
    },

    clockRunning: function () {
      var currentTime = new Date(),
        timeElapsed = new Date(
          this.countDownTime - (currentTime - this.timeBegan)
        ),
        //hour = timeElapsed.getUTCHours(), min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds(),
        ms = timeElapsed.getUTCMilliseconds();

      let remainTime = this.countDownTime - (currentTime - this.timeBegan);

      if (timeElapsed <= 0) {
        timeElapsed = 0;
        this.reset();
        this.$emit("end-game");
      }

      if (remainTime < 5000) {
        this.under5 = true;
      }

      this.time =
        /*zeroPrefix(hour, 2) + ":" +
                this.zeroPrefix(min, 2) + ":" +*/
        this.zeroPrefix(sec, 2) + "." + this.zeroPrefix(ms, 3);
    },
    zeroPrefix: function (num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    stop: function () {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono");
.time {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 3.5em;

  color: #000000;
}
.redColor {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 3.5em;
  color: #a81919;
}
</style>
