<template>
  <div class="home">
    <PlusMinus msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import PlusMinus from "@/components/PlusMinus.vue";

export default {
  name: "Home",
  components: {
    PlusMinus,
  },
};
</script>
