<template>
  <div id="clock">
    <div class="plus_title mt-5 mb-5">{{ topMessage }}</div>
    <v-card
      class="main_card mx-auto"
      max-width="450"
      align-items="center"
      outlined
      elevation="5"
    >
      <div class="start mt-10 mb-10" v-show="state == 'none'">
        <a id="start" @click="testStart">Start</a>
      </div>

      <div class="start" v-show="state == 'result'">
        <p>Score : {{ score }}</p>
        <a id="start" @click="initStart">ReStart</a>
      </div>

      <div v-show="state == 'ing'">
        <count-down-timer
          class="textaligntcentertype"
          ref="child"
          v-on:end-game="endGame"
        />
        <hr size="15px" />

        <div class="textaligntcentertype">
          <span class="time" id="time">{{ frontNum }}</span>
          <span class="time" id="time">{{ plmusminus }}</span>
          <span class="time" id="time">{{ backNum }}</span>
        </div>
        <div class="textaligntcentertype">
          <a class="time" @click="answer(bogi1)"> {{ bogi1 }}</a>
          <a class="time" @click="answer(bogi2)"> {{ bogi2 }}</a>
          <a class="time" @click="answer(bogi3)"> {{ bogi3 }}</a>
        </div>

        <hr size="15px" />
        <div class="score mt-3 mb-3">Score : {{ score }}</div>
      </div>
    </v-card>
    <add-this
      class="mb-5 mt-10"
      publicId="ra-61f7a13e40ab996e"
      data-title="덧셈 테스트"
      data-description="나의 떨어진 덧셈 실력 확인하기"
    />

    <v-card
      class="mx-auto mt-5"
      max-width="250"
      align-items="center"
      elevation="5"
    >
      <div class="stop mt-5 mb-5" v-show="state == 'ing'">
        <a id="stop" @click="initStart">Stop</a>
      </div>
    </v-card>
    <div class="ad-banner mt-5">
      <ins
        class="kakao_ad_area"
        style="display: none"
        data-ad-unit="DAN-qKzSbssHXSxqJSdM"
        data-ad-width="320"
        data-ad-height="100"
      ></ins>
    </div>
    <div class="ad-banner2 mt-5">
      <ins
        class="kakao_ad_area"
        style="display: none"
        data-ad-unit="DAN-d7ByzdzeZhVzu58x"
        data-ad-width="300"
        data-ad-height="250"
      ></ins>
    </div>
  </div>
</template>

<script>
import CountDownTimer from "./CountDownTimer.vue";
import AddThis from "./AddthisSection.vue";

export default {
  components: {
    CountDownTimer,
    AddThis,
  },
  data: function () {
    return {
      time: "00.000",
      frontNum: 0,
      backNum: 0,
      plmusminus: "+",
      bogi1: 0,
      bogi2: 0,
      bogi3: 0,
      collectAnswer: 0,
      state: "none",
      score: 0,
      topMessage: "버튼을 눌러 테스트를 시작하세요",
    };
  },
  methods: {
    testStart() {
      this.score = 0;
      this.state = "ing";
      this.$refs.child.start();
      this.changeProblem();
      this.topMessage = "과연 내 실력은..";
    },

    initStart() {
      this.$refs.child.reset();
      this.score = 0;
      this.state = "none";
      this.topMessage = "버튼을 눌러 테스트를 시작하세요";
    },

    updateResultMessage() {
      if (this.score < -3001) {
        this.topMessage = "화가 나신건 아니죠?";
      } else if (this.score < 2001) {
        this.topMessage = "컨디션이 안좋으셨나 봄ㅋㅋ";
      } else if (this.score < 4001) {
        this.topMessage = "진짜 실력은 아니죠?";
      } else if (this.score < 8001) {
        this.topMessage = "살짝 아쉽네요ㅎㅎ";
      } else if (this.score < 10001) {
        this.topMessage = "평타는 치시는군요";
      } else if (this.score < 12001) {
        this.topMessage = "제법이시군요";
      } else if (this.score < 14001) {
        this.topMessage = "미친속도시네요";
      } else {
        this.topMessage = "컴퓨터신가요";
      } //내가 이렇게 까지 됐다니
    },

    changeProblem() {
      let num1 = Math.floor(Math.random() * 99) + 1;
      let num2 = Math.floor(Math.random() * 99) + 1;

      if (Math.floor(Math.random() * 10) < 5) {
        //plus
        this.plmusminus = "+";
        this.collectAnswer = num1 + num2;
      } else {
        //minus
        this.plmusminus = "-";
        if (num1 < num2) {
          let tempNum = num1;
          num1 = num2;
          num2 = tempNum;
        }
        this.collectAnswer = num1 - num2;
      }

      this.frontNum = num1;
      this.backNum = num2;
      this.makeBogi();
    },

    makeBogi() {
      let b1 = this.collectAnswer + Math.floor(Math.random() * 20) - 10;
      let b2 = this.collectAnswer + Math.floor(Math.random() * 20) - 10;
      if (
        b1 == this.collectAnswer ||
        b2 == this.collectAnswer ||
        b1 == b2 ||
        b1 < 0 ||
        b2 < 0
      ) {
        this.makeBogi();
      } else {
        let rndNum = Math.floor(Math.random() * 100);
        if (rndNum < 33) {
          this.bogi1 = b1;
          this.bogi2 = b2;
          this.bogi3 = this.collectAnswer;
        } else if (rndNum < 66) {
          this.bogi1 = b1;
          this.bogi2 = this.collectAnswer;
          this.bogi3 = b2;
        } else {
          this.bogi1 = this.collectAnswer;
          this.bogi2 = b1;
          this.bogi3 = b2;
        }
      }
    },

    answer(num) {
      if (num == this.collectAnswer) {
        this.updateScroe(1);
      } else {
        this.updateScroe(-1);
      }
      this.changeProblem();
    },

    endGame() {
      //console.log('endgame')
      this.updateResultMessage();
      this.state = "result";
    },

    updateScroe(num) {
      this.score += num * 1000;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono");
.vcard {
  align-items: center;
}
.time {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 3.7em;
  text-align: center;

  color: #000000;
}
.start {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 3.7em;
  text-align: center;

  color: #000000;
}
.stop {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 2.7em;
  text-align: center;

  color: #000000;
}
.score {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 2.7em;
  text-align: center;

  color: #000000;
}
.plus_title {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 1.7em;
  text-align: center;

  color: #5d5d5d;
}
.textaligntcentertype {
  text-align: center;
}

.ad-banner {
  width: 320px;
  margin: auto;
}
.ad-banner2 {
  width: 300px;
  margin: auto;
}
.ad-banner_top {
  width: 320px;
  margin: auto;
}

@media (max-width: 600px) {
  .time {
    font-size: 3.2em;
  }
  .start {
    font-size: 3.2em;
  }
  .stop {
    font-size: 2.3em;
  }
  .score {
    font-size: 2.3em;
  }
  .plus_title {
    font-size: 1.4em;
  }
}
</style>
